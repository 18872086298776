<template>
  <div>

   <v-row style="display: flex;justify-content: center;margin-top: 5px;">
    <v-col md="2">
      <v-btn
      :color="isLoad==1?'success':'blue'"
      hide-details
          @click="clearFilters();isLoad=1;loadLogs();"
          style="display:block;margin-top:10px;"
          class="w-full h-btn white-clr"
        >
          {{ $t("Activities") }}
        </v-btn>

    </v-col>
    <v-col md="2">
      <v-btn
          :color="isLoad==2?'success':'blue'"
          hide-details
          @click="clearFilters2();isLoad=2;loadCustomerLogs();"
          style="display:block;margin-top:10px;"
          class="w-full h-btn white-clr"
        >
          {{ $t("CustomerActivities") }}
        </v-btn>

    </v-col>
   </v-row>
     <div class="breadCurmb mt-3">
      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label>{{ $t("WorkGroup") }}</label>
      <span>/</span>
      <label active>{{ isLoad == 1 ? $t("Activities") : $t('CustomerActivities') }}</label>
    </div>

   <v-toolbar flat>
          <v-toolbar-title>{{ isLoad == 1 ? $t('ActivitiesTable') : $t('CustomerActivitiesTable') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        
    <!-- Start Filters -->
    <div v-if="isLoad == 1">


        <v-row>

           <v-col :md="3">
        <div>
          <label>{{$t('User')}}</label>
         <v-autocomplete
            :clearable="true"
            class="d-block my-2"
            name="action_by_id"
            hide-details
            dense
            outlined
            :multiple="false"
            item-text="name"
            item-value="id"
            v-model="filters.action_by_id"
            :items="adminList"
          ></v-autocomplete>
        </div>
      </v-col>


       <v-col :md="3">
        <div>
          <label>{{$t('Type')}}</label>
         <v-autocomplete
            :clearable="true"
            class="d-block my-2"
            name="type"
            hide-details
            dense
            outlined
            :multiple="false"
            item-text="name"
            item-value="id"
            v-model="filters.type"
            :items="typeList"
          ></v-autocomplete>
        </div>
      </v-col>

     <v-col :md="1" class="mt-6">
        <v-btn
          color="third"
          hide-details
          @click="getDataFromApi"
          style="display:block;margin-top:10px;"
          class="w-full h-btn white-clr"
          small
        >
          {{ $t("Filter") }}
        </v-btn>
      </v-col>
      <v-col :md="1" class="mt-6">
        <v-btn
          color="danger"
          hide-details
          @click="clearFilters();isLoad=1;loadLogs();"
          style="display:block;margin-top:10px;"
          class="w-full h-btn white-clr"
          small
        >
          {{ $t("Clear") }}
        </v-btn>
      </v-col>
    </v-row>
    </div>

    <div v-else-if="isLoad == 2">


      <v-row>

        <v-col :md="2">
      <div>
        <label>{{$t('CustomerID')}}</label>
      <v-text-field
          class="d-block my-2"
          name="id"
          hide-details
          dense
          outlined
          v-model="filters2.id"
        ></v-text-field>
      </div>
      </v-col>

      <v-col :md="2">
      <div>
        <label>{{$t('CustomerName')}}</label>
      <v-text-field
          class="d-block my-2"
          name="name"
          hide-details
          dense
          outlined
          v-model="filters2.name"
        ></v-text-field>
      </div>
      </v-col>

      <v-col :md="2">
      <div>
        <label>{{$t('CustomerMobile')}}</label>
      <v-text-field
          class="d-block my-2"
          name="phone_number"
          hide-details
          dense
          outlined
          v-model="filters2.phone_number"
        ></v-text-field>
      </div>
      </v-col>

      <v-col :md="2">
      <div>
        <label>{{$t('CustomerEmail')}}</label>
      <v-text-field
          class="d-block my-2"
          name="email"
          hide-details
          dense
          outlined
          v-model="filters2.email"
        ></v-text-field>
      </div>
      </v-col>
<!-- 
      <v-col :md="3">
          <div>
            <label>{{ $t("FromDate") }}</label>
            <el-date-picker
                style="width: 100%"
                v-model="filters2.from_date"
                type="date"
                class="mt-2"
                name="from_date"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('FromDate')"
                hide-details
                dense
                outlined
              >
              </el-date-picker>
          </div>
        </v-col>

        <v-col :md="3">
          <div>
            <label>{{ $t("ToDate") }}</label>
            <el-date-picker
                style="width: 100%"
                v-model="filters2.to_date"
                type="date"
                class="mt-2"
                name="from_date"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('ToDate')"
                hide-details
                dense
                outlined
              >
              </el-date-picker>
          </div>
        </v-col> -->

      <v-col :md="1" class="mt-6">
      <v-btn
        color="third"
        hide-details
        @click="getDataFromApi2"
        style="display:block;margin-top:10px;"
        class="w-full h-btn white-clr"
        small
      >
        {{ $t("Filter") }}
      </v-btn>
      </v-col>
      <v-col :md="1" class="mt-6">
      <v-btn
        color="danger"
        hide-details
        @click="clearFilters2();isLoad=2;loadCustomerLogs();"
        style="display:block;margin-top:10px;"
        class="w-full h-btn white-clr"
        small
      >
        {{ $t("Clear") }}
      </v-btn>
      </v-col>
      </v-row>


</div>

    
    
    <!-- End Filters -->


    <!-- Start DataTable -->
    <v-data-table
      v-if="isLoad==1"
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      :items-per-page="table.itemsPerPage"
      hide-default-footer
      class="elevation-1"
      :loading-text="$t('LoadData')"
    >
      <template v-slot:no-data>
        <v-alert class="mt-5 white-clr" color="third">
          {{ $t("NoData") }}
        </v-alert>
      </template>

      <template v-slot:item.key="{ item }">
        <a
          @click="goTo(item.key)"
          v-if="!item.key.includes('auth_admin') && !item.key.includes('destroy')"
          class="my-0 clickable"
          style="color:blue !important;"
        >
        {{ $t(item.key) }}
        </a>
        <span v-else> {{ $t(item.key) }}</span>
      </template>
      
      
    </v-data-table>

    <v-data-table
      v-else-if="isLoad==2"
      :headers="table2.headers"
      :items="table2.items"
      :loading="table2.loading"
      :items-per-page="table2.itemsPerPage"
      hide-default-footer
      class="elevation-1"
      :loading-text="$t('LoadData')"
    >
      <template v-slot:no-data>
        <v-alert class="mt-5 white-clr" color="third">
          {{ $t("NoData") }}
        </v-alert>
      </template>

      <template v-slot:item.key="{ item }">
        <a
          @click="goTo(item.key)"
          v-if="!item.key.includes('auth_admin') && !item.key.includes('destroy')"
          class="my-0 clickable"
          style="color:blue !important;"
        >
        {{ $t(item.key) }}
        </a>
        <span v-else> {{ $t(item.key) }}</span>
      </template>


      <template v-slot:item.actions="{ item }">
        <v-icon
          class="my-0 mr-2 ml-2"
          dense
          @click="goToCustomerActivitiesDetails(item)"
          color="success"
          >mdi-eye</v-icon
        >
      </template>

      
      
    </v-data-table>

    <!-- End DataTable -->

    <!-- Start Pagination -->
    <div v-if="isLoad == 1" class="text-center pt-2">
      <v-pagination
        v-if="table.length"
        total-visible="5"
        v-model="table.page"
        :length="table.length"
        @input="changePage(table.page)"
      ></v-pagination>
    </div>

     <!-- Start Pagination -->
     <div v-else-if="isLoad == 2" class="text-center pt-2">
      <v-pagination
        v-if="table2.length"
        total-visible="5"
        v-model="table2.page"
        :length="table2.length"
        @input="changePage2(table2.page)"
      ></v-pagination>
    </div>

    <!-- End Pagination -->
  </div>
</template>

<script>
export default {
  name: "activities",
  data() {
    return {
               user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : [],
      adminList: [],
      isLoad: 1,
      typeList: [],
      filters: {
        action_by_id: null,
        type: null,
      },
      filters2: {
        id: null,
        name: null,
        phone_number: null,
        email: null,
      },
      config: {
        tableName: "ActivitiesTable",
        crudHeader: "Activities",
        crudApi: "activity",
        filterWidth: "20rem",
        deleteModalWidth: "30rem",
        modalFormWidth: "50rem",
      },
      table: {
        page: 1,
        length: null,
        itemsPerPage: 10,
        loading: false,
        items: [],
        headers: [
          { text: "#", value: "id", width: "5rem" },
          {
            text: this.$t("User"),
            value: "action_by_user",
            width: "10rem",
          },
          {
            text: this.$t("Type"),
            value: "type_text",
            width: "20rem",
          },
          {
            text: this.$t("Key"),
            value: "key",
            width: "10rem",
          },
           {
            text: this.$t("Event"),
            value: "event",
            width: "20rem",
          },
          //   {
          //   text: this.$t("Url"),
          //   value: "url",
          //   width: "10rem",
          // },
          {
            text: this.$t("CreatedAt"),
            value: "created_at",
            width: "10rem",
          },
        ],
      },

      table2: {
        page: 1,
        length: null,
        itemsPerPage: 10,
        loading: false,
        items: [],
        headers: [
          { text: "#", value: "id", width: "5rem" },
          {
            text: this.$t("CustomerName"),
            value: "name",
            width: "10rem",
          },
          {
            text: this.$t("CustomerMobile"),
            value: "phone_number",
            width: "10rem",
          },
          {
            text: this.$t("CustomerMobile") + ' 2',
            value: "mobile",
            width: "10rem",
          },
          {
            text: this.$t("CustomerEmail"),
            value: "email",
            width: "10rem",
          },
          {
            text: this.$t("Actions"),
            value: "actions",
            sortable: false,
            align: "center",
            width: "8rem",
          }
        ],
      },
    };
  },
  methods: {
    goToCustomerActivitiesDetails(item) {
      this.$router.push({ name: 'activitiesCustomerDetails', params: { id: item.id } })
    },
    getTypeList() {
      this.$store.dispatch(`activity/listTypes`).then(res => {
        this.typeList=res.data;
      })
    },
    getAdminList() {
      this.$store.dispatch(`activity/listAdmins`).then(res => {
        this.adminList=res.data;
      })
    },
    goTo(key){
      if(key.includes('subscribe')) this.$router.push({name: 'subscribe'});
      else if(key.includes('admins')) this.$router.push({name: 'user'});
      else if(key.includes('group_name')) this.$router.push({name: 'group_name'});
      else if(key.includes('company')) this.$router.push({name: 'company'});
      else if(key.includes('bank_names')) this.$router.push({name: 'bank_name'});
      else if(key.includes('periods')) this.$router.push({name: 'period'});
      else if(key.includes('branches')) this.$router.push({name: 'branch'});
      else if(key.includes('new_menus')) this.$router.push({name: 'menu'});
      else if(key.includes('recipies')) this.$router.push({name: 'recipie'});
      else if(key.includes('ingredients')) this.$router.push({name: 'ingredient'});
      else if(key.includes('units')) this.$router.push({name: 'unit'});
      else if(key.includes('nutrition_facts')) this.$router.push({name: 'nutrition_fact'});
      else if(key.includes('tools')) this.$router.push({name: 'tool'});
      else if(key.includes('groups')) this.$router.push({name: 'group'});
      else if(key.includes('divisions')) this.$router.push({name: 'division'});
      else if(key.includes('cuisines')) this.$router.push({name: 'cuisine'});
      else if(key.includes('packages')) this.$router.push({name: 'package'});
      else if(key.includes('cities')) this.$router.push({name: 'city'});
      else if(key.includes('countries')) this.$router.push({name: 'country'});
      else if(key.includes('subscribtion')) this.$router.push({name: 'subscribe'});
      else if(key.includes('complaintDivisions')) this.$router.push({name: 'complaint_division'});
      else if(key.includes('complaintTypes')) this.$router.push({name: 'complaint_type'});
    },
    clearFilters() {
      for (let key in this.filters) {
        this.filters[key] = null;
      }
    },
    clearFilters2() {
      for (let key in this.filters2) {
        this.filters2[key] = null;
      }
    },
    loadLogs() {
      this.getDataFromApi();
    },
    loadCustomerLogs() {
      this.getDataFromApi2();
    },
    getDataFromApi() {
      this.table.loading = true;

    let sendData = {...this.filters, page: this.table.page};
      this.$store.dispatch(`activity/getData`,sendData)
        .then((res) => {
          this.table.items = res.data.resources;
          this.table.length = Math.ceil(res.data.pagination.total / res.data.pagination.per_page);
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    getDataFromApi2() {
      this.table2.loading = true;

    let sendData = {...this.filters2, page: this.table2.page};
      this.$store.dispatch(`activity/getData2`,sendData) // need to change
        .then((res) => {
          this.table2.items = res.data.resources;
          this.table2.length = Math.ceil(res.data.pagination.total / res.data.pagination.per_page);
        })
        .finally(() => {
          this.table2.loading = false;
        });
    },
    changePage(page) {
      this.table.page = page;
      this.getDataFromApi();
    },
    changePage2(page) {
      this.table2.page = page;
      this.getDataFromApi2();
    },
  },
  mounted() {
    this.getTypeList();
    this.getAdminList();
    this.getDataFromApi();
  },
};
</script>